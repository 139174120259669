* {
    margin: auto;
    padding: 0;
}

html {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    margin-right: calc(100vw - 100%);
    font-family: DM Sans, sans-serif;
}

/* FONTS */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('http://fonts.cdnfonts.com/css/century-schoolbook');

@font-face {
    font-family: "James";
    src: local("james1-regular-webfont"), url("./fonts/james1-regular-webfont.woff") format("woff");
}

.main-wrapper {
    min-height: calc(100vh - 72px);
}

.page-title {
    font-family: "James"
}

.custom-font-button {
    font-family: "James"
}

.starter-page {
    font-weight: 600;
    font-family: "James"
}

/* NAVBAR */
.navbar {
    color: black;
}

.navbar-james {
    font-family: "James"
}

.mobile-navigation {
    background-color: gray.200;
}

.footer-pin {
    position: "relative";
    left: 0;
    bottom: 0;
    right: 0;
}

/* HOME PAGE */
.home-page {
    margin-top: 10vh;
    font-weight: 600;
}

.home-page-typewriter {
    max-width: 90%;
    margin: auto;
    padding-top: 10vh;
    font-size: 6vh;
}

.contact-page {
    margin: auto;
    text-align: center;
}

.connect-image {
    border-radius: 25px;
}

/* Start Page */
.from-california {
    font-size: 70px;
    background-color: #FAF2C3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.in-ithaca {
    font-size: 70px;
    background-color: #E0FFFF;
    display: flex;
    height: 100%
}

/* About Page */
.about-section {
    text-align: center;
    font-size: large;
}

/* Employment */
.employment-card {
    transition: all 200ms ease-in-out;
}

.employment-card:hover {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    box-shadow: 0px 12px 10px lightgray;
}

/* Coursework & Birthdays */
.hover-card {
    transition: all 200ms ease-in-out;
}

.hover-card:hover {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    box-shadow: 0px 12px 10px lightgray;
}

/* Skills */
.skills-card {
    transition: all 200ms ease-in-out;
}

.skills-card:hover {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    box-shadow: 0px 12px 10px lightgray;
}

/* Start Page */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

.scroll-icon {
    animation: fadeIn 1s infinite alternate;
}

/* Blog */
.blog-card {
    transition: all 200ms ease-in-out;
}

.blog-card:hover {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    box-shadow: 0px 12px 10px lightgray;
}

.blog-title {
    font-family: "James"
}

a:hover {
    text-decoration: none;
}

.blog-images {
    height: auto;
    width: auto;
    max-width: 500px;
    max-height: 500px;
}

.birthday-card-link {
    width: 80%
}